<template>
  <div class="about">
    <section class="hero has-background-white-bis">
      <div class="hero-body">
        <div class="container">
          <p class="title">للتواصل</p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <p class="subtitle has-text-centered">نرجوا مراسلتنا على البريد أدناه أو تعبئة النموذج للتواصل.</p>
        <p class="has-text-centered"><a href="mailto:info@madinahwild.com">info@madinahwild.com</a></p>
        <section class="section">
          <form method="post" @submit.prevent="validateForm()">
            <div v-if="show" class="notification" :class="type">
              <button class="delete" @click="hideWarning"></button>
              {{ message }}
            </div>
            <div class="field">
              <label class="label">الاسم</label>
              <p class="control">
                <input class="input" placeholder="الاسم" v-model="contactForm.name" v-validate="'required'" name="contact_name" :class="{'input': true, 'is-danger': errors.has('contact_name') }" />
                <span class="help is-danger" v-show="errors.has('contact_name')">{{ errors.first('contact_name') }}</span>
              </p>
            </div>
            <div class="field">
              <label class="label">البريد الإلكتروني</label>
              <p class="control">
                <input class="input" placeholder="email@example.com" v-model="contactForm.email" v-validate="'required|email'" name="contact_email" :class="{'input': true, 'is-danger': errors.has('contact_email') }" dir="ltr" />
                <span class="help is-danger" v-show="errors.has('contact_email')">{{ errors.first('contact_email') }}</span>
              </p>
            </div>
            <div class="field">
              <label class="label">الرسالة</label>
              <div class="control">
                <textarea class="textarea" placeholder="المحتوى" v-model="contactForm.message" v-validate="'required'" name="contact_message" :class="{'input': true, 'is-danger': errors.has('contact_message') }"></textarea>
                <span class="help is-danger" v-show="errors.has('contact_message')">{{ errors.first('contact_message') }}</span>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-primary" type="submit" :class="{ 'is-loading': getLoader }">إرسال</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ar from 'vee-validate/dist/locale/ar'

export default {
  data () {
    return {
      contactForm: {
        name: null,
        email: null,
        message: null
      },
      dictionary: {
        ar: {
          attributes: {
            contact_name: '"الاسم"',
            contact_email: '"البريد الإلكتروني"',
            contact_message: '"المحتوى"'
          }
        }
      },
      type: null,
      show: false,
      message: null
    }
  },
  created () {
    this.$validator.localize('ar', ar)
    this.$validator.localize(this.dictionary)
  },
  mounted () {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LebDo8UAAAAAElRyYXwTXuhQ_Xjv7wpLTrlZ_-T')
    recaptchaScript.setAttribute('id', 'recapcha')
    document.head.appendChild(recaptchaScript)
  },
  beforeDestroy () {
    let getRecaptcha = document.getElementById('recapcha')
    let footRecaptcha = document.querySelector('.grecaptcha-badge')
    document.head.removeChild(getRecaptcha)
    footRecaptcha.remove(footRecaptcha)
  },
  methods: {
    validateForm: function () {
      var self = this
      this.$validator.validateAll().then((result) => {
        if (result) {
          self.$store.dispatch('showLoader', true)
          window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LebDo8UAAAAAElRyYXwTXuhQ_Xjv7wpLTrlZ_-T', { action: '/contact' }).then(function (token) {
              let params = {
                name: self.contactForm.name,
                email: self.contactForm.email,
                message: self.contactForm.message,
                captcha: token
              }
              axios.post('https://www.madinahwild.com/handle/', params)
                .then(result => {
                  self.$store.dispatch('showLoader', false)
                  if (result.data && result.data.result) {
                    self.show = true
                    self.type = 'is-success'
                    self.message = result.data.message
                  } else {
                    self.show = true
                    self.type = 'is-danger'
                    self.message = result.data.message
                  }
                  self.contactForm.name = null
                  self.contactForm.email = null
                  self.contactForm.message = null
                })
                .catch(error => {
                  self.$store.dispatch('showLoader', false)
                  if (error) {
                    self.show = true
                    self.type = 'is-danger'
                    self.message = 'حصل خطأ أثناء الإرسال. يرجى المحاولة مرة أخرىز'
                  }
                  self.contactForm.name = null
                  self.contactForm.email = null
                  self.contactForm.message = null
                })
            })
          })
        }
      })
    },
    hideWarning: function () {
      this.type = null
      this.show = false
      this.message = null
    }
  },
  computed: {
    ...mapGetters([
      'getLoader'
    ])
  }
}
</script>
